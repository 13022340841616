import React from 'react'

export default function Producer() {
  return (
    <div>
      <p>James has spent the last 2 years at DHB studio working with various musicians producing indie and rock music.</p>
      <p>More to be added here soon...</p>
      <p></p>
    </div>
  )
}
